//
// Login 1
// Pages SASS files are compiled into separate css files
//

// Initialization of global variables, mixins and functions
@import "../../../init";

.form-background {
  background: #e5e5e5;
}

.login.login-1 {
  // Form modes
  .login-signin,
  .login-signup,
  .login-forgot {
    display: none;
  }

  &.login-signin-on {
    .login-signup {
      display: none;
    }

    .login-signin {
      display: block;
    }

    .login-forgot {
      display: none;
    }
  }

  &.login-signup-on {
    .login-signup {
      display: block;
    }

    .login-signin {
      display: none;
    }

    .login-forgot {
      display: none;
    }
  }

  &.login-forgot-on {
    .login-signup {
      display: none;
    }

    .login-signin {
      display: none;
    }

    .login-forgot {
      display: block;
    }
  }
}


.form-error {
  border: 1px solid #FF4B55 !important;
}

.form-error-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #FF4B55;
}

.pdfiFrame {
  pointer-events: fill;
  width: 100%;
  height: 100%;
}

h3.form-title {
  font-size: 18px;
}

.form-title.light {
  opacity: .8;
}

.rotate180 {
  transform: rotateY(180deg);
}

.form-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 22.5px;
  color: #2f3044;
  margin-bottom: .3em;
}

.link-text {
  &:hover {
    color: blue;
    cursor: pointer;
  }
}

.form-control-text {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3F4254;
}

.carrier-form-header {
  font-weight: 600;
  font-size: 25px;
  color: #2f3044;
  text-align: center;
}

.form-eye-icon {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 20px;
  cursor: pointer;
}

.dashboard-form-eye-icon {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 20px;
  cursor: pointer;
}

.InputTooltip {
  position: absolute;
  top: -3.25em;
  background: rgba(0, 0, 0, 0.75);
  padding: 8px 6px;
  border-radius: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #ffffff;
  width: max-content;
}

.InputTooltipArrow {
  position: absolute;
  display: flex;
  top: -0.3em;
  left: .8em;
}

.form-head-text {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #464e5f;
  margin-bottom: 0;
}

.form-head-desc {
  font-size: 12px;
  line-height: 20px;
  color: #80808f;
}

.form-head-desc.bold {
  font-weight: 600 !important;
}

.login.login-1 {
  height: 100vh;
  width: 100vw;
}

.login-aside {
  background: #f0f3f5 !important;
  position: relative;
  overflow-x: auto;
}

.left-menu-title {
  font-size: 24px;
  line-height: 36px;
  color: #464e5f;
  font-weight: 600;
}

.form-container {
  width: 100%;
  align-self: center;
}


.form-area {
  background: #ffffff !important;
  border-radius: 0px !important;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  padding: 2.5em;
}

.phone-input-container {
  background-color: #F3F6F9;
  width: 100%;
  height: 67px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
}

.phone-input-button {
  background-color: #F3F6F9;
  border: none !important;
  padding: 0 1em !important;
  position: relative !important;
}

.phone-input-input {
  background-color: #F3F6F9 !important;
  padding: 0 !important;
  border: none !important;
  outline: none !important;
  width: inherit !important;
}

.menuIcon {
  background: #3699ff1a;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.menuCollapseIcon {
  background: #3699ff1a;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: absolute;
  top: 30px;
  right: 30px;
}

.helpIcon {
  background: #3699ff1a;
  height: 66px;
  width: 66px;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 0 1em 1em 0;
}

.left-menu-desc {
  color: #80808f;
  font-size: 12px;
  line-height: 20px;
}

.left-menu-box {
  background: #ffffff;
  display: flex;
  padding: 1em;
  margin: 1em 0;
  border-radius: 3px;
}

.left-menu-box-icon {
  height: 46px;
  width: 46px;
  background: rgba(54, 153, 255, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-menu-box-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 22.5px;
  color: #2f3044;
}

.left-menu-box-description {
  font-weight: 500;
  font-size: 15px;
  line-height: 22.5px;
  color: #80808f;
}

.faq-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #2f3044;
  margin-left: 2em;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.faq-item {
  display: flex;
  border-top: 1px solid #e8e8e8;
  padding: 0.45em 0;
  cursor: pointer;
}

.faq-content {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #80808f;
  opacity: 0;
}

.opacity-animation {
  animation: 1s opacityFrame forwards;
}

@keyframes opacityFrame {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.languageContainer {
  position: absolute;
  top: 2em;
  right: 2.25em;
}

.languageDropdown {
  min-height: 5%;
  width: 15%;
  min-width: 170px;
  background: #ffffff;
  border: 1px solid #B5B5C3;
  border-radius: .3rem;
  padding: 13px;
  cursor: pointer;
  position: relative;
}

.borderless {
  border: none !important;
}

.languageDropdownFlag {
  max-height: 24px;
  min-width: 24px;
  border: 1px solid black;
  border-radius: 50%;
}

.languageDropdownText {
  font-weight: 600;
  font-size: 13px;
  line-height: 19.5px;
  color: #2F3044;
  margin-left: .85em;
}

.languageDropdownMenu {
  background: #FFFFFF;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  position: absolute;
  top: 4.5em;
  right: 0;
  width: 100% !important;
  padding: 0 13px;
  z-index: 9999;
  min-width: 170px;
}

.languageDropdownMenuText {
  color: #80808F;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  margin: 0 2.60em 0 .85em;
}

.checkIconDropdown {
  position: absolute;
  right: 0px;
}

.selectComponent {
  background-color: #F3F6F9;
  border-color: #F3F6F9;
  color: #3F4254;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  padding: 0 1.5rem;
  height: 50px;
  width: 100% !important;
  border-radius: .5em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.selectComponentArrow {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 0px;
}

.optInput {
  width: 70px;
  height: 70px;
  background: #FFFFFF;
  border: 1px solid #80808F;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
}

.optWarn {
  border: 1px solid #FF4B55 !important;
}

.otpContainer {
  max-width: 500px;
}

.optSuccess {
  border: 1px solid rgba(0, 194, 51, 0.65);
}

.optWarnText {
  font-size: 12px;
  line-height: 20px;
  color: #FF4B55;
}

.optSuccessText {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 194, 51, 0.65);
}

.pointer {
  cursor: pointer !important;
}

.finalFormText {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

.timer-text {
  font-size: 1em;
  font-style: normal;
  font-weight: 600;
  position: absolute;
  top: .3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.timer-desc-text {
  font-size: 8px;
  line-height: 0px;
  color: #80808F;
}

.btn.btn-secondary:disabled {
  opacity: 1 !important;
}

.passwordBar {
  height: .5em;
  width: 100%;
  background: #EFF0F3;
  border-radius: 10px;
}

.strengthBar {
  height: .5em;
  border-radius: 10px;
}

.otpTimerText {
  font-size: 16px;
  font-weight: 500;
}

.registration-icon-background {
  height: 46px;
  width: 46px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-active {
  background: rgba(54, 153, 255, 0.1);
}

.step-passed {
  background: rgba(0, 194, 51, 0.1);
}

.step-passive {
  background: rgba(181, 181, 195, 0.08);
}

.registration-step-container {
  display: flex;
  align-items: center;
  align-self: flex-start;
  width: 100%;
}

.register-icon-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.register-icon-container:last-child {
  justify-content: flex-start;
}

.registration-step-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.registration-line {
  width: 3em;
  border: 1px dashed #B5B5C3;
  justify-self: end;
  margin: 0 1em;
}

.ios {
  width: 200px;
  background: #3699FF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 17px;
  padding: 10px;
  cursor: pointer;
}

.android {
  width: 200px;
  background: #FFFFFF;
  border: 1px solid rgba(16, 29, 73, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #101651;
  font-size: 17px;
  padding: 10px;
  cursor: pointer;
}

.languageModal {
  height: 100%;
  width: 100vw;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 9999;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.languageModalContent {
  width: 75%;
  height: 75vh;
  position: fixed;
  background: #ffffff;
  border-radius: 1em;
  padding: 8% 10% 5% 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &.loginType {
    background: #f1f1f1;
    max-width: 900px;
    max-height: 580px;

    & > .timesIcon {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
}

.loginTypeSelector {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

}

.typeContainer {
  background: #fff;
  width: 270px;
  margin-left: 46px;
  height: 270px;
  border-radius: .8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  & > div {
    height: 270px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;

    & > h6 {
      color: #121D2F;
      font-size: 18px;
    }

    & > span {
      color: #878791;
      font-size: 14px;
    }

    & > svg {
      margin-bottom: 1em;
    }
  }
}

.flagsContainer {
  display: flex;
  flex-wrap: wrap;
}

.languageModalFlag {
  width: 70%;
  margin: 1em auto;
  height: intrinsic;
}

.selectedFlag {
  opacity: .4;
}

.checkFlag {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.passwordValidation {
  position: absolute;
  top: 10%;
  right: -22em;
}

.passwordValidationText {
  font-size: 17px;
  line-height: 20px;
  color: #BEBEBE;
}

.forgotPasswordQuestionText {
  font-size: 17px;
  color: #2F3044;
}

.recaptcha {
  margin: .75rem 0;
}

.vatInputDropdown {
  position: absolute;
  top: 70px;
  left: 0px;
  width: 100%;
  background: white;
  border-radius: .42rem;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 1em .8em;
  z-index: 999;
  max-height: 30vh;
  overflow: auto;
}

.vatDropdownItem {
  padding: 1em .8em;
  background-color: white;
  transition: .3s;
  cursor: pointer;
}

.vatDropdownItem:hover {
  background-color: #F3F6F9;
  border-radius: .42rem;
  transition: .3s;
}


.base-timer {
  position: relative;
  width: 300px;
  height: 300px;
}

.base-timer__svg {
  transform: scaleX(-1);
  width: 100%;
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: #EEF0F8;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(20deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
}

.base-timer__path-remaining.red {
  color: red;
}

.base-timer__label {
  position: absolute;
  width: 300px;
  height: 300px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
}

.login-aside::-webkit-scrollbar {
  display: none;
}


// Desktop view
@include media-breakpoint-up(lg) {
  .login.login-1 {
    .login-aside.left-menu-minimize {
      width: 100%;
      overflow: hidden;
      animation: .5s menuMinimize forwards;
      padding: 2.5em !important;
    }

    .login-aside {
      width: 100%;
      max-width: 517px;
      padding: 2.5em;
    }

    .login-form {
      width: 100%;
      max-width: 600px !important;
    }
  }
}


@keyframes menuMinimize {
  from {
    max-width: 450px
  }
  to {
    max-width: 70px
  }
}

.menuOpen {
  animation: .5s menuOpen forwards;
}

@keyframes menuOpen {
  from {
    max-width: 100px
  }
  to {
    max-width: 450px
  }
}

@media only screen and (max-width: 1690px) and (min-width: 992px) {
  .login-form {
    width: 100%;
    max-width: 35vw !important;
  }
  #generatePassword {
    width: 75%;
  }
}

@media only screen and (max-height: 900px) {
  .languageModalContent {
    padding: 2.5% 10% 2% 10%;
  }
  #finalForm {
    padding: 2em !important;
  }
  .form-area {
    overflow: auto !important;
  }
}

#registration-page-div {
  margin-bottom: 3em !important;
}

//Fixing Ipad Pro
@media only screen and (max-width: 1100px) and (min-width: 900px) and (max-height: 1500px) and (min-height: 1200px) {
  .register-icon-container:last-child {
    width: 1000px;
  }
  #registration-page-div {
    width: 100%;
  }
  #registration-page-div {
    width: 100%;
  }
}

@media only screen and (max-width: 1300px) {
  .registration-step-title {
    display: none;
  }
  .step-active ~ .registration-step-title {
    display: block;
  }
}


// Tablet and Mobile mode
@include media-breakpoint-between(lg, xl) {
  .login.login-1 {
    .login-aside {
      width: 100%;
      max-width: 400px;
      padding: 2.5em 2em 2.5em 3em;
    }
  }
  .passwordValidation {
    position: relative;
    top: 0;
    right: 0;
    margin-bottom: 2em;
  }
  #generatePassword {
    width: 100%;
    max-width: 600px;
  }
  .login-form {
    width: 100% !important;
    max-width: none !important;
  }
  input:focus {
    outline: inherit !important;
  }
  #buttonGroups > button {
    padding: 1em !important;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 48px;
  }
  #finalFormContext {
    width: 90% !important;
  }
}


// Mobile and Tablet mode
@include media-breakpoint-down(md) {
  .login.login-1 {
    .login-form {
      width: 100%;
      max-width: 100%;
    }
  }
  .login-aside {
    padding: 1em 2.5em !important;
  }
  #menu {
    padding: 1em !important;
  }
  .languageModal {
    align-items: flex-start;
    padding: 4% 0;
  }
  .languageModalContent {
    height: 90vh;
    overflow: auto;
    width: 90%;
  }
  .flagsContainer > .w-25 {
    width: 50% !important;
  }
  .login.login-1 {
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
  }
  .login-form {
    width: 100% !important;
  }
  .recaptcha {
    margin: .5rem;
  }
  .languageDropdown {
    border: 0;
  }
  #buttonGroups {
    flex-direction: column-reverse;
    margin-top: 1em;
  }
  #buttonGroups > button {
    width: 100%;
  }
  .create-account-button {
    padding: 1rem .5rem !important;
    margin: 1rem 0 !important;
    width: 150px;
    justify-content: center;
  }
  #buttonGroupLeftText {
    flex-direction: column;
    width: 100%;
  }
  .recaptcha {
    display: flex;
    justify-content: center;
  }
  .form-button {
    width: 100%;
  }
  .form-left-button {
    flex-direction: column;
  }
  input:focus, select:focus {
    outline: inherit !important;
  }
  .passwordValidation {
    position: relative;
    top: 0;
    right: 0;
    margin-bottom: 2em;
  }
  #generatePassword {
    width: 100%;
  }
  .registration-step-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .register-icon-container {
    width: 100%;
  }
  .registration-line {
    margin: 0 auto;
  }
  .registration-step-title {
    display: none;
    white-space: nowrap;
  }
  .registration-line {
    width: 20%;
  }
  .step-active ~ .registration-step-title {
    display: block;
  }
  #finalFormContext {
    width: 85% !important;
  }
  #appMarkets {
    flex-direction: column;
  }
  .android {
    margin: .5em 0 !important;
  }
  .languageContainer {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 0;
    right: 0;
  }
  .register-icon-container:last-child {
    width: 30%;
  }
  #OPTInputs {
    justify-content: center !important;
  }
  .optInput:not(:first-child) {
    margin-left: .5em;
  }
  .optInput {
    height: 50px;
    width: 50px;
  }
  .otpContainer {
    max-width: none !important;
  }
  .menuCollapseIcon {
    position: relative;
    top: 0;
    right: 0;
  }
  .form-background {
    background-color: #f0f3f5 !important;
  }
  .form-container {
    max-height: none !important;
  }
  .form-area {
    min-height: 100vh;
    padding: 1em;
  }
  #registration-page-div {
    width: 100%;
  }
  #registrant {
    margin-bottom: 1em;
  }
  .loginTypeSelector {
    flex-direction: column;
  }
  .languageModalContent.loginType {
    max-height: unset;
  }
  .typeContainer {
    margin-left: 0 !important;
    &:last-child {
      margin-top: 46px;
    }
  }
}

@include media-breakpoint-down(xs) {
  .register-icon-container:last-child {
    width: 55%;
  }
  .languageContainer {
    margin-bottom: 3em;
  }
}

span.account-select {
  font-weight: 500;
  font-family: Poppins, Helvetica, "sans-serif";
  font-size: 24px;
  text-align: center;
  color: #000;
}

.account-select-icon-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 3em;
  @media only screen and (max-width: 769px) {
    flex-direction: column;
  }

  & > * {
    width: 300px;
  }
}

.svg-container {
  min-height: 230px;
  max-height: 230px;
  margin-bottom: 1em;
}

.account-select-logo {
  width: 40vw;
  max-width: 300px;
  min-width: 150px;
  margin-bottom: 10vh;
}

.account-select-icon {
  height: 270px;
  width: 270px;
}

