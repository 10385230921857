//
// Burger Icon Mixins
//

@mixin burger-icon-build($config) {
  $space: get($config, thickness) + get($config, space);

  @include button-reset();

  display: inline-block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-size: 0;
  text-indent: -9999px;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  background-color: none;
  outline: none !important;
  width: get($config, width);
  height: get($config, height);
  transition: get($config, transition);

  span {
    display: block;
    position: absolute;
    @include border-radius(get($config, radius));
    transition: get($config, transition);
    top: (get($config, height) / 2) - (get($config, thickness)) + 1px;
    height: get($config, thickness);
    min-height: get($config, thickness);
    width: 100%;

    &::before,
    &::after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: get($config, thickness);
      min-height: get($config, thickness);
      content: "";
      transition: get($config, transition);
      @include border-radius(get($config, radius));
    }

    &::before {
      top: -($space);
    }

    &::after {
      bottom: -($space);
    }
  }

  // Left Direction
  &-left {
    span {
      &:before {
        transition: get($config, transition);
        left: auto;
        right: 0px;
        width: 50%;
      }

      &:after {
        transition: get($config, transition);
        left: auto;
        right: 0px;
        width: 75%;
      }
    }

    // Active State
    &-active {
      span {
        &:before {
          transition: get($config, transition);
          left: 0px;
          right: auto;
          width: 50%;
        }

        &:after {
          transition: get($config, transition);
          left: 0px;
          right: auto;
          width: 75%;
        }
      }
    }
  }

  // Right Direction
  &-right {
    span {
      &:before {
        left: 0px;
        right: auto;
        width: 50%;
        transition: get($config, transition);
      }

      &:after {
        left: 0px;
        right: auto;
        width: 75%;
        transition: get($config, transition);
      }
    }

    // Active State
    &-active {
      span {
        &:before {
          left: auto;
          right: 0px;
          width: 50%;
          transition: get($config, transition);
        }

        &:after {
          left: auto;
          right: 0px;
          width: 75%;
          transition: get($config, transition);
        }
      }
    }
  }
}

@mixin burger-icon-theme($default, $hover, $active) {
  span {
    background-color: $default;

    &::before,
    &::after {
      background-color: $default;
    }
  }

  // Hover State
  &:hover {
    span {
      background-color: $hover;

      &::before,
      &::after {
        background-color: $hover;
      }
    }
  }

  // Active State
  &-active {
    span {
      background-color: $active;

      &::before,
      &::after {
        background-color: $active;
      }
    }
  }
}
