//
// Separator
//

.separator {
  height: 0;

  // Dashed style
  &.separator-dashed {
    border-bottom: 1px dashed $border-color;
  }

  &.separator-solid {
    border-bottom: 1px solid $border-color;
  }

  &.separator-border-2 {
    border-bottom-width: 2px;
  }

  &.separator-border-3 {
    border-bottom-width: 3px;
  }

  &.separator-border-4 {
    border-bottom-width: 4px;
  }

  // Theme colors
  @each $name, $color in $theme-colors {
    &.separator-#{$name} {
      border-bottom-color: $color;
    }
  }
}
