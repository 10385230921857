//
// SVG
//

// Base
.svg {
  @each $name, $color in $theme-text-colors {
    &.svg-fill-#{$name} {
      fill: $color !important;
    }

    @if (theme-light-color($name)) {
      &.svg-fill-light-#{$name} {
        fill: theme-light-color($name) !important;
      }
    }
  }
}
